import {request} from '../../../util/Request'
import Error from "../../../util/Error";

const DEALER_TO_DEALER_LOAD_DOCUMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

const DEFAULT_DEALERTODEALER_ORDER_STATE = {
    supplier_carrier_id: null,
    to_dealer_id: null,
    from_location_id: null,
    from_zone_id: null,
    from_range_id: null,
    to_location_id: null,
    skip_dealer_confirmation: true,
    to_zone_id: null,
    to_range_id: null,
    client_id: null,
    price_id: null,
    price_period_id: null,
    route_id: null,
    document: {...DEALER_TO_DEALER_LOAD_DOCUMENT_STATE},
    documents: [],
    ids: [],
    errors: null,
}

const DEFAULT_FORM_STATE = {
    orders: [], // DEFAULT_DEALERTODEALER_ORDER_STATE
}

export default {
    data() {
        return {
            dealerToDealerSelectedOrders: [],
            groupedDealerToDealerOrders: [],
            selectedDealerToDealerOrderLength: 0,
            dealerToDealerLoadFormVisible: false,
            dealerToDealerLoadFormState: {...DEFAULT_FORM_STATE},
            dropdowns: {
                carrierSuppliers: [],
                zones: [],
            },
        }
    },
    methods: {
        handleDealerToDealerLoadAddClick() {
            const self = this
            let from_to_keys = [] // array of from location id and to Location ids
            _.map(self.dealerToDealerSelectedOrders, (item) => {
                from_to_keys.push(`${item.dealer_location.id} ${item.client.id} ${item.dealer.id} ${item.brand.id} ${item.dealer_for_dealer_to_dealer.id} ${item.dealer_to_dealer_location.id} `)
            })

            _.map(_.uniqBy(from_to_keys), (item, index) => {
                const locations = item.split(' ')
                const items = _.filter(self.dealerToDealerSelectedOrders, (order) => (
                    order.dealer_location.id === locations[0] &&
                    order.client.id === locations[1] &&
                    order.dealer.id === locations[2] &&
                    order.brand.id === locations[3] &&
                    order.dealer_for_dealer_to_dealer.id === locations[4] && // coming DD update from API
                    order.dealer_to_dealer_location.id === locations[5] // coming from DD update API
                ))

                const fromZone = this.getZone(items[0].dealer_location.zip * 1);
                const toZone = this.getZone(items[0].dealer_to_dealer_location.zip * 1);

                self.groupedDealerToDealerOrders.push({
                    id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                    from_location: items[0].dealer_location,
                    dealer: items[0].dealer,
                    client: items[0].client,
                    brand: items[0].brand,
                    items: items,
                    models: _.uniqBy(items, 'model.id').map(i => i.model.id),
                    from_zone: fromZone,
                    to_zone: toZone,
                    to_location: items[0].dealer_to_dealer_location,
                    suppliers: [],
                })

                self.dealerToDealerLoadFormState.orders.push({
                    ids: items.map((order) => order.id), // OrderIds
                    items: [],
                    client_id: items[0].client.id,
                    from_location_id: items[0].dealer_location.id,
                    from_zone_id: fromZone.zone_id,
                    from_range_id: fromZone.range_id,
                    supplier_carrier_id: null,
                    to_dealer_id: items[0].dealer_for_dealer_to_dealer.id,
                    to_location_id: items[0].dealer_to_dealer_location.id,
                    to_zone_id: toZone.zone_id,
                    to_range_id: toZone.range_id,
                    price_id: null,
                    price_period_id: null,
                    route_id: null,
                    skip_dealer_confirmation: true,
                    errors: '',
                    document: {...DEALER_TO_DEALER_LOAD_DOCUMENT_STATE},
                    documents: [],
                })
            })

            this.dealerToDealerLoadFormVisible = true
            this.getDealerToDealerOrderNearLocationSupplierCarrier()
        },
        async handleDealerToDealerLoadSubmit(urlToSend = 'dealer/to/dealer/loads/create') {
            if (this.validateLoad())
                return;

            try {
                const response = await request({
                    url: urlToSend,
                    method: 'post',
                    data: {...this.dealerToDealerLoadFormState},
                })

                this.loadCreateSuccess()
                this.handleDealerToDealerLoadFormOperationClose()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.validationError()
                    return false
                }

                this.handleServerError(error)
            }
        },
        async getDealerToDealerOrderNearLocationSupplierCarrier() {
            try {
                const response = await request({
                    url: '/dropdowns/orders/matched/location/dd/carrier/suppliers',
                    method: "post",
                    data: {
                        orders: this.groupedDealerToDealerOrders.map((item) => ({
                            id: item.id,
                            from_location: item.from_location,
                            brand: item.brand,
                            models: item.models,
                            ids: item.items.map((order) => order.id), // OrderIds
                            to_location: item.to_location
                        })),
                    }
                })

                const {data} = response
                _.map(data, (group, index) => {
                    const i = _.findIndex(this.groupedDealerToDealerOrders, (i) => (i.id == group.id))
                    this.$set(this.groupedDealerToDealerOrders[i], 'suppliers', group.suppliers)
                })
            } catch (e) {

            }
        },
        validateLoad() {
            let errors = false

            _.map(this.dealerToDealerLoadFormState.orders, (item, index) => {
                this.dealerToDealerLoadFormState.orders[index].errors = '';

                if (!item.supplier_carrier_id || !item.from_zone_id || !item.from_range_id  || !item.to_zone_id || !item.to_range_id) {
                    errors = true
                    this.dealerToDealerLoadFormState.orders[index].errors = this.$t('msc.requiredFieldsMissing') + '<br>'
                } else if(!item.price_id || !item.route_id || !item.price_period_id) {
                    errors = true
                    this.dealerToDealerLoadFormState.orders[index].errors = this.$t('msc.unableToDecidePrice') + '<br>'
                }
            })

            return errors;
        },
        handleDealerToDealerRowSelection(item, rowIndex, event) {
            let response = true;

            if ((item.dealer_location && item.dealer_location.id) &&
                (item.dealer_for_dealer_to_dealer && item.dealer_for_dealer_to_dealer.id) &&
                (item.dealer_to_dealer_location && item.dealer_to_dealer_location.id) &&
                item.load_status === 17 && item.tr_status === 3 && item.tr_status_type === "dd"
                && (item.client_tr_at && item.client_tr_accept_at) && item.has_call_of_dd) {
                const index = _.findIndex(this.dealerToDealerSelectedOrders, {id: item.id});
                if (index >= 0) { // if already exist then delete
                    setTimeout(() => this.$refs.dealerToDealerTable.unselectRow(rowIndex), 200)
                    delete this.dealerToDealerSelectedOrders[index]
                    this.selectedDealerToDealerOrderLength = this.selectedDealerToDealerOrderLength - 1
                } else { // else add new
                    this.dealerToDealerSelectedOrders.push(item)
                    setTimeout(() => this.$refs.dealerToDealerTable.selectRow(rowIndex), 200)
                    this.selectedDealerToDealerOrderLength = this.selectedDealerToDealerOrderLength + 1
                }
            } else {
                response = false
            }

            this.dealerToDealerSelectedOrders = _.compact(this.dealerToDealerSelectedOrders)
            return response;
        },
        handleDealerToDealerLoadFormOperationClose(refreshList = true) {
            this.dealerToDealerLoadFormState = {...DEFAULT_FORM_STATE}
            this.dealerToDealerLoadFormState.orders.length = 0
            this.dealerToDealerLoadFormVisible = false
            this.groupedDealerToDealerOrders.length = 0
            this.dealerToDealerSelectedOrders.length = 0
            this.selectedDealerToDealerOrderLength = 0
            this.$refs.dealerToDealerTable.clearSelected()
            this.formErrors = new Error({})

            if (refreshList) { // to handle call from refreshList (When page change)
                this.refreshList()
            }
        },
        handleAddUpdateDealerToDealerLoadDocumentClick(groupIndex) {
            this.dealerToDealerLoadFormState.orders[groupIndex].document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.dealerToDealerLoadFormState.orders[groupIndex].document.document || this.dealerToDealerLoadFormState.orders[groupIndex].document.document.length <= 0 || !this.dealerToDealerLoadFormState.orders[groupIndex].document.document.id) {
                this.dealerToDealerLoadFormState.orders[groupIndex].document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.dealerToDealerLoadFormState.orders[groupIndex].document.title || _.trim(this.dealerToDealerLoadFormState.orders[groupIndex].document.title).length <= 2) {
                this.dealerToDealerLoadFormState.orders[groupIndex].document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.dealerToDealerLoadFormState.orders[groupIndex].documents, (document) => {
                if (document.token !== this.dealerToDealerLoadFormState.orders[groupIndex].document.token) {
                    if (document.title === this.dealerToDealerLoadFormState.orders[groupIndex].document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.dealerToDealerLoadFormState.orders[groupIndex].document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.dealerToDealerLoadFormState.orders[groupIndex].document.error) return false;

            const entity = this.dealerToDealerLoadFormState.orders[groupIndex].document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.dealerToDealerLoadFormState.orders[groupIndex].documents, {token: entity.token});

            if (this.dealerToDealerLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.dealerToDealerLoadFormState.orders[groupIndex].documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.dealerToDealerLoadFormState.orders[groupIndex].documents.push(entity)
            }

            this.dealerToDealerLoadFormState.orders[groupIndex].document = {
                ...DEALER_TO_DEALER_LOAD_DOCUMENT_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeleteDealerToDealerLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.dealerToDealerLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.dealerToDealerLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.dealerToDealerLoadFormState.orders[groupIndex].documents.splice(index, 1);
            }
        },
        handleEditDealerToDealerLoadDocumentClick(groupIndex, token) {
            const index = _.findIndex(this.dealerToDealerLoadFormState.orders[groupIndex].documents, {token: token})
            if (this.dealerToDealerLoadFormState.orders[groupIndex].documents[index] !== undefined) {
                this.dealerToDealerLoadFormState.orders[groupIndex].document = {...this.dealerToDealerLoadFormState.orders[groupIndex].documents[index]};
            }
        },
        resetDealerToDealerLoadDocument(groupIndex) {
            this.dealerToDealerLoadFormState.orders[groupIndex].document = {...DEALER_TO_DEALER_LOAD_DOCUMENT_STATE}
            this.dealerToDealerLoadFormState.orders[groupIndex].document.error = false;
        },
        handleAfterLocationCreate(inputs, index) {
            const locations = [...this.dropdowns.locations, {id: inputs.id, label: inputs.label, ...inputs}];
            this.$set(this.dropdowns, 'locations', locations)
            this.dealerToDealerLoadFormState.orders[index].to_location_id = inputs.id;
        },
        handleAfterLocationUpdate(inputs, index) {
            const locations = [...this.dropdowns.locations, {id: inputs.id, label: inputs.label, ...inputs}];
            this.$set(this.dropdowns, 'locations', locations)
            this.dealerToDealerLoadFormState.orders[index].to_location_id = inputs.id;
        },
        handleAfterSupplierCreate(inputs, index) {
            const supplierCarriers = [...this.dropdowns.supplierCarriers, {id: inputs.id, label: inputs.name}];
            this.$set(this.dropdowns, 'supplierCarriers', supplierCarriers)
            this.dealerToDealerLoadFormState.orders[index].supplier_carrier_id = inputs.id;
        },
        handleAfterSupplierUpdate(inputs, index) {
            const supplierCarriers = [...this.dropdowns.supplierCarriers, {id: inputs.id, label: inputs.name}];
            this.$set(this.dropdowns, 'supplierCarriers', supplierCarriers)
            this.dealerToDealerLoadFormState.orders[index].supplier_carrier_id = inputs.id;
        },
        getZone(zip) {
            let zone = {zoneName: null, range: null, zone_id: null, range_id: null};

            _.map(this.dropdowns.zones, (item) => {
                const range = _.filter(item.ranges, (r) => (zip >= (r.zip_from*1) && zip <= (r.zip_to*1)))

                if(range.length > 0) {
                    zone = {zoneName: item.label, range: range[0], zone_id: item.id, range_id: range[0].id}
                }
            })

            return zone;
        },
        handleSupplierChange(value, group, index) {
            this.dealerToDealerLoadFormState.orders[index].route_id = null;
            this.dealerToDealerLoadFormState.orders[index].price_id = null;
            this.dealerToDealerLoadFormState.orders[index].price_period_id = null;
            this.dealerToDealerLoadFormState.orders[index].loading_factors = null;
            this.dealerToDealerLoadFormState.orders[index].items = [];
            if(!value) {
                return false;
            }

            let price = _.find(group.suppliers, {id: value}).prices;

            if (price) {
                price = price[0];
                this.dealerToDealerLoadFormState.orders[index].route_id = price.route.id
                this.dealerToDealerLoadFormState.orders[index].price_id = price.id
                this.dealerToDealerLoadFormState.orders[index].price_period_id = price.price_periods[0].id
                this.dealerToDealerLoadFormState.orders[index].loading_factors = price.loading_factors

                if (price.loading_factors > 0 && group.items.length > price.loading_factors) {
                    const numberOfLoads = Math.ceil(group.items.length / price.loading_factors);
                    this.dealerToDealerLoadFormState.orders[index].loading_factor_note = numberOfLoads + ' ' + this.$t('msc.loadsWillBeCreated')

                    const orders = this.dealerToDealerLoadFormState.orders[index].ids;
                    let a = 0; let b = 0;

                    const items = [];
                    for (let i = 1; i <= numberOfLoads; i++) {
                        let item = []

                        for (let j = 0; j < price.loading_factors; j++) {
                            if(orders[a]) {
                                item.push(orders[a++]);
                            }
                        }

                        items[b] = item;
                        b++;
                    }
                    this.dealerToDealerLoadFormState.orders[index].items = items;
                } else {
                    this.dealerToDealerLoadFormState.orders[index].items = [[...this.dealerToDealerLoadFormState.orders[index].ids]];
                }

            } else {
                this.priceNotFound()
            }
        },
    },
}
