<template>
    <div>
        <div v-if="show">
            <div class="card" v-show="operation !== 'detail'">
                <div class="card-header card-header-flex pb-2">
                    <div class="w-100 mt-2">
                        <div class="row">
                            <div class="col-8">
                                <h5 class="mt-3 ml-0 mr-3 mb-2">
                                    <strong>
                                        <template v-if="operation === null">{{$t('title.deliveryRequests')}}</template>
                                        <template v-else>{{ $t(operationTitle) }}</template>
                                    </strong>
                                </h5>
                            </div>
                            <div class="col-4 text-right">
                                <div v-if="operation === null">
                                    <div class="mt-3">
                                        <!-- Using components -->
                                        <b-input-group class="mt-3">
                                            <b-form-input type="search" class="form-control form-control-sm"
                                                          :placeholder="$t('input.whatAreYouLookingFor')"
                                                          v-on:keyup.enter="handleSearch"
                                                          v-model="search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="handleDealerToDealerLoadAddClick" size="sm"
                                                          :title="$t('button.title.createDealerToDealerLoad')"
                                                          :disabled="selectedDealerToDealerOrderLength <= 0"
                                                          class="btn btn-default bg-gray-6"
                                                          v-if="$global.hasPermission('ddloadsstore')"
                                                          v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> <strong>D </strong>
                                                    <b-badge variant="dark">{{selectedDealerToDealerOrderLength}}
                                                    </b-badge>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                          variant="outline-info"
                                                          @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersownview')">
                                                    <i class="fa fa-filter"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.resetList')"
                                                          variant="outline-info"
                                                          @click="handleResetClick()" v-b-tooltip.hover>
                                                    <i class="fa fa-refresh"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button variant="warning" size="sm" class="mt-3"
                                              @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </div>
                        </div><!-- /.row -->
                    </div><!-- /.w-100 -->
                </div><!-- /.card-header -->
                <div class="card-body">
                    <div class="orders-table">
                        <b-table hover responsive
                                 :busy="listingLoading"
                                 :items="dataSource"
                                 :fields="columns"
                                 :sort-by.sync="sortField"
                                 ref="dealerToDealerTable"
                                 selectable
                                 @sort-changed="handleSortChange"
                                 @row-clicked="handleRowClick">
                            <template v-slot:cell(order_id)="{ detailsShowing, item, index, rowSelected}">
                                <div class="position-relative">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                    </template>
                                    <a @click="toggleDetails(item)" class="d-inline in-center">
                                        <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                    </a>
                                    {{ (item.order_id ? item.order_id : '') }}
                                </div>
                            </template>
                            <template v-slot:cell(status)="record">
                                {{ record.item._status }}
                            </template>
                            <template v-slot:cell(load_status)="record">
                                <div>
                                    <ol-status :status="record.item.load_status"></ol-status>
                                </div>
                                <tr-status v-if="record.item.tr_status > 0" :status="record.item.tr_status"
                                           :type="record.item.tr_status_type"></tr-status>
                            </template>
                            <template v-slot:cell(brand_id)="record">
                                {{ (record.item.brand ? record.item.brand.title : '') }}
                                <div>{{ (record.item.model ? record.item.model.title : '') }}</div>
                            </template>
                            <template v-slot:cell(client_tr_at)="{item}">
                                {{item.client_tr_at ? $global.utcDateToLocalDate(item.client_tr_at) : ''}}
                            </template>
                            <template v-slot:cell(dealer_location)="{item}">
                                <print-ad :item="item.dealer_location" titled="1"></print-ad>
                            </template>
                            <template v-slot:cell(dealer_to_dealer_location)="{item}">
                                <print-ad :item="item.dealer_to_dealer_location" titled="1"></print-ad>
                            </template>
                            <template v-slot:cell(dealer_id)="{item}">
                                {{item.dealer ? item.dealer.name : ""}}
                            </template>
                            <template v-slot:cell(dealer_for_dealer_to_dealer)="{item}">
                                {{item.dealer_for_dealer_to_dealer ? item.dealer_for_dealer_to_dealer.name : ""}}
                            </template>
                            <template v-slot:row-details="{ item }">
                                <b-card>
                                    <b-list-group flush>
                                        <b-list-group-item><strong>{{$t('column.importerNumber')}}</strong>:
                                            {{item.invoice_number_importer}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.comment')}}</strong>: {{item.comments}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.outboundSupplierCarrier')}}</strong>:
                                            {{item.outbound_supplier_carrier ? item.outbound_supplier_carrier.name :
                                            ''}}
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <strong>{{$t('title.destinationLocation')}}</strong>:
                                            <template v-if="item.dealer_location">
                                                <print-ad :item="item.dealer_location"></print-ad>
                                            </template>
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.transportRequestAt')}}</strong>:
                                            {{item.client_tr_at ? $global.utcDateToLocalDate(item.client_tr_at) : ''}}
                                        </b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.transportRequestAcceptedAt')}}</strong>:
                                            {{item.client_tr_accept_at ?
                                            $global.utcDateToLocalDate(item.client_tr_accept_at) : ''}}
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                            <template v-slot:cell(action)="record">
                                <a @click="setOperation('detail', record.item.id)"
                                   :title="$t('button.title.detailItem')" class=" ml-1"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </template>
                        </b-table><!-- /.b-table -->
                        <div class="clearfix">
                            <div class="float-left ">
                                <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                               size="sm"></b-form-select>
                            </div>
                            <div class="float-right">
                                <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                                ></b-pagination>
                            </div><!-- /.pull-right -->
                        </div><!-- /.clearfix -->
                    </div><!-- /.orders-table-->
                    <div class="orders-operation">
                    </div><!--/.orders-operation-->
                    <div class="filter-container">
                        <a-drawer
                            placement="left"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="filters.visible = !filters.visible"
                            :visible="!operation && filters.visible"
                            :zIndex="10"
                            :title="$t('title.advanceFilters')"
                        >
                            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.fromAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.toAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <div class="drawer-footer">
                                    <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                              class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                        {{$t('button.close')}}
                                    </b-button>
                                    <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.reset')}}
                                    </b-button>
                                    <b-button size='sm' variant="primary" button="submit" type="filled"
                                              :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </form>
                        </a-drawer>
                    </div><!-- /.filter-container -->
                </div><!-- /.card-body-->
            </div><!-- /.card -->
            <div v-if="operation === 'detail'">
                <detail :handle-close-operation="handleOperationClose"></detail>
            </div>

            <!-- # Start DealerToDealer Load -->
            <a-drawer
                placement="right"
                :width="'86%'"
                :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                :closable="false"
                @close="handleDealerToDealerLoadFormOperationClose"
                :visible="dealerToDealerLoadFormVisible"
                :zIndex="10"
                :title="$t('title.createDealerToDealerLoad')"
            >
                <form @submit.prevent="() => handleDealerToDealerLoadSubmit('dealer/to/dealer/loads/create')"
                      autocomplete="off">
                    <b-row v-if="groupedDealerToDealerOrders.length > 0">
                        <b-col sm="12" v-for="(group, index) in groupedDealerToDealerOrders" :key="index">
                            <b-row>
                                <b-col sm="12" lg="2" md="2" class=" mb-sm-3">
                                    <h4 class="d-sm-inline">
                                    <span class="bg-gray-6 ml-2 rounded text-white p-1">
                                        {{$t('title.load')}}#<strong>{{index + 1}}</strong>
                                    </span>
                                    </h4>
                                    <h4 class="mt-4 ml-2 d-sm-inline">
                                    <span class="bg-light rounded p-1">
                                        <small>{{$t('title.orders')}}-{{group.items.length}}</small>
                                    </span>
                                    </h4>
                                </b-col>
                                <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                    <dl>
                                        <dt>{{$t('title.fromLocation')}}:</dt>
                                        <dd>
                                            <print-ad :item="group.from_location"></print-ad>
                                        </dd><!--/fromLocation-->
                                        <dt>{{$t('title.zone')}}:</dt>
                                        <dd>{{(group.from_zone || {}).zoneName}}</dd><!--/fromLocation-->
                                        <dt>{{$t('title.fromDealer')}}:</dt>
                                        <dd>{{(group.dealer || {}).name}}</dd><!--/dealer-->

                                        <dt>VIN:</dt>
                                        <dd>
                                            <div v-for="(order, index) in group.items">
                                                {{order.vin_number}}
                                            </div>
                                        </dd>
                                    </dl>
                                </b-col>
                                <b-col sm="12" lg="2" md="2" class="ml-sm-1 mt-sm-2">
                                    <dl>
                                        <dt>{{$t('title.brand')}}:</dt>
                                        <dd>{{group.brand.title}}</dd><!--/brand-->

                                        <dt>{{$t('title.model')}}:</dt>
                                        <dd>
                                            <div v-for="(order, index) in _.uniqBy(group.items, 'model.title')">
                                                {{order.model.title}}-{{order.model.version_code}}
                                            </div>
                                        </dd><!--/model-->

                                        <dt>{{$t('title.client')}}:</dt>
                                        <dd>{{group.client.company_name}}</dd><!--/model-->
                                    </dl>
                                </b-col>
                                <b-col sm="12" lg="4" md="4">
                                    <div>
                                        <small v-if="dealerToDealerLoadFormState.orders[index].errors"
                                               class="mb-2 d-block">
                                        <span class="text-danger"
                                              v-html="dealerToDealerLoadFormState.orders[index].errors"></span>
                                        </small>
                                    </div>
                                    <div class="form-group mb-2">
                                        <div class="clearfix">
                                                <span class="pull-left">
                                                    <label class="d-block">{{$t('input.supplierCarrier')}} *</label>
                                                </span><!-- /.pull-left -->
                                            <span class="pull-right">
                                                <quick-supplier-form
                                                    allow-update="1"
                                                    allow-create="1"
                                                    :id="dealerToDealerLoadFormState.orders[index].supplier_carrier_id"
                                                    :after-create="(inputs) => handleAfterSupplierCreate(inputs, index)"
                                                    :after-update="(inputs) => handleAfterSupplierUpdate(inputs, index)">
                                                </quick-supplier-form>
                                            </span><!-- /.pull-right -->
                                        </div><!-- /.clearfix -->
                                        <treeselect
                                            :multiple="false"
                                            :options="group.suppliers"
                                            placeholder=""
                                            v-model="dealerToDealerLoadFormState.orders[index].supplier_carrier_id"
                                            :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id'))}]"
                                            @input="(value, instance) => handleSupplierChange(value, group, index)"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('supplier_carrier_id')}}</div>
                                    </div><!-- /.form-group -->

                                    <div class="form-group mb-2">
                                        <label class="d-block">{{$t('input.toLocation')}} *</label>
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            :disabled="true"
                                            v-model="dealerToDealerLoadFormState.orders[index].to_location_id"
                                            :class="[{'invalid is-invalid': (formErrors.has('to_location_id'))}]"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('to_location_id')}}</div>
                                    </div><!-- /.form-group -->

                                    <div class="form-group mb-2">
                                        <dt>{{$t('title.zone')}}:</dt>
                                        <dd>{{(group.to_zone || {}).zoneName}}</dd><!--/fromLocation-->
                                    </div>

                                    <div class="form-group mb-2">
                                        <label class="d-block">{{$t('input.dealer')}} *</label>
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.dealers"
                                            placeholder=""
                                            :disabled="true"
                                            v-model="dealerToDealerLoadFormState.orders[index].to_dealer_id"
                                            :class="[{'invalid is-invalid': (formErrors.has('to_dealer_id'))}]"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('to_dealer_id')}}</div>
                                    </div><!-- /.form-group -->
                                    <div class="row mb-2">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <b-form-checkbox
                                                    v-model="dealerToDealerLoadFormState.orders[index].skip_dealer_confirmation"
                                                    name="check-button">{{$t('input.skipDealerConfirmation')}}
                                                </b-form-checkbox>
                                            </div>
                                            <div class="col-sm-12">
                                                <small v-if="dealerToDealerLoadFormState.orders[index].loading_factors > 0">
                                                    {{$t('title.loadingFactors')}}:
                                                    {{dealerToDealerLoadFormState.orders[index].loading_factors}}
                                                </small>
                                            </div>
                                            <div class="col-sm-12">
                                                <br>
                                                <small class="text-danger" v-if="dealerToDealerLoadFormState.orders[index].loading_factors > 0"
                                                       v-html="dealerToDealerLoadFormState.orders[index].loading_factor_note"></small>
                                                <small class="text-danger" v-if="!dealerToDealerLoadFormState.orders[index].price_id">
                                                    {{ $t('msc.unableToDecidePrice') }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col lg="12" md="12" sm="12" class="mt-3 mb-5">
                                    <b-card class="mb-0">
                                        <b-card-header v-b-toggle="'dealerToDealer-load-documents-'+index" class="p-0">
                                            <h4 class="mb-0">
                                            <span class="badge badge-primary">
                                                {{$t('title.attachments')}}
                                            </span>
                                                <small
                                                    v-show="dealerToDealerLoadFormState.orders[index].documents.length > 0">
                                                    - {{dealerToDealerLoadFormState.orders[index].documents.length}}
                                                    Item/s
                                                </small>
                                            </h4>
                                        </b-card-header><!-- /.p-0-->
                                        <b-collapse :id="`dealerToDealer-load-documents-${index}`">
                                            <div class="bg-light p-3">
                                                <b-row>
                                                    <b-col lg="4" md="4" sm="12">
                                                        <div class="form-group">
                                                            <b-form-group
                                                                :label="$t('input.title')+' *'"
                                                                label-for="title"
                                                                :invalid-feedback="formErrors.first('title')"
                                                            >
                                                                <b-form-input
                                                                    id="title"
                                                                    v-model="dealerToDealerLoadFormState.orders[index].document.title"
                                                                    type="text"
                                                                    :state="((formErrors.has('title') ? false : null))"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                            <div class="invalid-feedback">{{formErrors.first('title')}}
                                                            </div>
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="4" md="4" sm="12">
                                                        <b-form-group class="mt-4 pt-2">
                                                            <upload
                                                                v-model="dealerToDealerLoadFormState.orders[index].document.document"
                                                                :disabled="dealerToDealerLoadFormState.orders[index].document.document"
                                                                :title="(dealerToDealerLoadFormState.orders[index].document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                                css-class="mt-0 btn-sm"></upload>
                                                            <b-button :title="$t('msc.removeUpload')"
                                                                      type="button"
                                                                      variant="outline-primary"
                                                                      v-b-tooltip.hover
                                                                      class="ml-2 ml-2 btn-sm"
                                                                      @click="() => {dealerToDealerLoadFormState.orders[index].document.document = null;}"
                                                                      :disabled="!dealerToDealerLoadFormState.orders[index].document.document"
                                                                      v-if="dealerToDealerLoadFormState.orders[index].document.document">
                                                                <i class="fa fa-close"></i>
                                                            </b-button>
                                                            <b-button :title="$t('button.download')"
                                                                      v-b-tooltip.hover
                                                                      type="button"
                                                                      variant="outline-primary"
                                                                      class="ml-2 ml-2 btn-sm"
                                                                      v-if="dealerToDealerLoadFormState.orders[index].document.document && dealerToDealerLoadFormState.orders[index].document.document.download_url"
                                                                      :disabled="!(dealerToDealerLoadFormState.orders[index].document.document && dealerToDealerLoadFormState.orders[index].document.document.download_url)"
                                                                      :href="(dealerToDealerLoadFormState.orders[index].document.document ? dealerToDealerLoadFormState.orders[index].document.document.download_url : '')"
                                                                      target="_blank">
                                                                <i class="fa fa-cloud-download"></i>
                                                            </b-button>
                                                        </b-form-group>
                                                        <div class="invalid-feedback d-block">
                                                            {{formErrors.first('document')}}
                                                        </div>
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block">
                                                                <pre> </pre>
                                                            </label>
                                                            <b-button variant="info" type="button" size="sm"
                                                                      class="btn-block"
                                                                      @click="handleAddUpdateDealerToDealerLoadDocumentClick(index)">
                                                                <i class="fa fa-plus"></i>
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block">
                                                                <pre> </pre>
                                                            </label>
                                                            <b-button variant="warning" type="button" size="sm"
                                                                      class="btn-block"
                                                                      @click="resetDealerToDealerLoadDocument(index)">
                                                                {{$t('button.reset')}}
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                                <b-row>
                                                    <b-col cols="12">
                                                        <table class="table table-bordered bg-white">
                                                            <thead>
                                                            <tr>
                                                                <th width="50">#</th>
                                                                <th width="180">{{$t('column.title')}}</th>
                                                                <th width="60">{{$t('column.action')}}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(ct, i) in dealerToDealerLoadFormState.orders[index].documents"
                                                                :class="[{'table-primary': ct.token === dealerToDealerLoadFormState.orders[index].document.token}]">
                                                                <td>{{i + 1}}</td>
                                                                <td>
                                                                    {{ct.title}}
                                                                </td>
                                                                <td>
                                                                    <a @click="handleEditDealerToDealerLoadDocumentClick(index, ct.token)"
                                                                       :title="$t('button.title.editItem')"
                                                                       v-b-tooltip.hover>
                                                                        <i class="fe fe-edit"></i>
                                                                    </a>
                                                                    <a :title="$t('button.download')" class=" ml-1"
                                                                       :href="ct.document.download_url"
                                                                       target="_blank" v-b-tooltip.hover>
                                                                        <i class="fa fa-cloud-download"></i>
                                                                    </a>
                                                                    <a-popconfirm title="Are you sure？"
                                                                                  @confirm="handleDeleteDealerToDealerLoadDocumentClick(index, ct.token)">
                                                                        <i slot="icon" class="fe fe-trash"></i>
                                                                        <a class=" ml-1"
                                                                           :title="$t('button.title.deleteItem')"
                                                                           v-b-tooltip.hover>
                                                                            <i class="fe fe-trash"></i>
                                                                        </a>
                                                                    </a-popconfirm>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                            <tfoot
                                                                v-show="dealerToDealerLoadFormState.orders[index].documents.length <= 0">
                                                            <tr>
                                                                <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                                            </tr>
                                                            </tfoot>
                                                        </table><!-- /.table table-bordered -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                            </div><!-- /.bg-light -->
                                        </b-collapse><!-- /#dealerToDealer-load-documents-->
                                    </b-card><!-- /b-card -->
                                </b-col><!--/b-col-->
                            </b-row><!--/.row-->
                            <hr/>
                        </b-col><!--/.col-->
                    </b-row><!--/.row-->

                    <div class="drawer-footer">
                        <b-button
                            size="sm"
                            type="submit"
                            variant="primary"
                            :disabled="global.pendingRequests > 0"
                            v-b-tooltip.hover :title="$t('button.title.save')"
                        >
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.save')}}
                        </b-button>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleDealerToDealerLoadFormOperationClose()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div><!--/.drawer-footer-->
                </form><!--/form-->
            </a-drawer><!--/.drawer-->
        </div>
    </div><!--/div-->
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import {itemUpdated} from "../../../util/Notify"
    import Detail from '../detail';
    import CreateDealerToDealerMixin from "./CreateDealerToDealerMixin";
    import QuickSupplierForm from "../../suppliers/QuickSupplierForm";

    const FORM_STATE = {
        visible: false,
        comments: null,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.vin'),
            key: 'vin_number',
            sortable: true,
        },
        {
            label: self.$t('column.fromDealer'),
            key: 'dealer_id',
            sortable: true,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'dealer_location',
            sortable: false,
            class: 'w-220'
        },
        {
            label: self.$t('column.toDealer'),
            key: 'dealer_for_dealer_to_dealer',
            sortable: false,
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'dealer_to_dealer_location',
            sortable: false,
            class: 'w-220'
        },
        {
            label: self.$t('column.brand') + '/' + self.$t('column.model'),
            key: 'brand_id',
            sortable: true,
        },
        {
            label: self.$t('column.deliveryRequest'),
            key: 'client_tr_at',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'load_status',
            sortable: true,
        },
        {
            label: self.$t('column.action'),
            class: 'text-right',
            key: 'action',
            width: 150,
        }
    ];

    export default {
        mixins: [ListingMixin, CreateDealerToDealerMixin],
        components: {
            Treeselect,
            Detail,
            QuickSupplierForm
        },
        data() {
            return {
                operationTitle: 'title.transportRequests',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'orders/dtd/transport/requests',
                columns: COLUMN_DEFINITION(this),
                show: true,
                selectedLoad: null,
                dealerToDealerLoadFormVisible: false,
                groupedDealerToDealerOrders: [],
                selectedDealerToDealerOrderLength: 0,
                dropdowns: {
                    currencies: [],
                    locations: [],
                    zones: [],
                    dealers: [],
                    supplierCarriers: [],
                }
            }
        },
        mounted() {
            this.getLocations();
            this.getDealers();
            this.getZones();
            this.getCurrencies();
            this.getSupplierCarriers();
            this.$title = this.$t('topBar.navigations.modules.transportRequests')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            handleRowClick(item, rowIndex, event) {
                this.handleDealerToDealerRowSelection(item, rowIndex, event)
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {

            },
            async handleEditClick(item) {

            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data

                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post",
                    })

                    const {data} = response
                    this.dropdowns.currencies = data
                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getZones() {
                try {
                    const response = await request({
                        url: '/dropdowns/zones',
                        method: "post",
                        data: {
                            range: 1
                        }
                    })

                    const {data} = response
                    this.dropdowns.zones = data
                } catch (e) {
                    this.dropdowns.zones = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = data
                } catch (e) {
                    this.dropdowns.dealers = []
                    this.dropdowns.dealersLocations = []
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('ordersownview')
            },
            refreshList() {
                this.loadList()
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .w-220 {
        width: 220px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 1379px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    .indicator-added-inbound-load {
        bottom: -15px;
    }

    .indicator-added-outbound-load {
        bottom: -15px;
    }
</style>
