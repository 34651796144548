<template>
	<fragment>
		<a :title="$t('button.title.addItem')"
		   v-if="($global.hasPermission('suppliersstore') || ($global.hasRole('client') && $global.hasPermission('suppliersownview'))) && allowCreate > 0"
		   v-b-tooltip.hover
		   @click="handleAddSupplierClick"
			class="mr-1">
			<i class="fe fe-plus"></i>
		</a>
		<a :title="$t('button.title.editItem')"
		   v-if="$global.hasPermission('suppliersupdate') && hasId && allowUpdate > 0"
		   @click="handleEditSupplierClick"
		   v-b-tooltip.hover>
			<i class="fe fe-edit"></i>
		</a>
		<b-modal v-model="visibility" size="lg" :title="operationTitle" hide-footer>
			<div class="brand-operation">
				<form @submit.prevent="handleSubmit" autocomplete="off">
					<b-row>
						<b-col cols="12">
							<div v-show="editLoading">
								<b-skeleton-table
									:rows="5"
									:columns="2"
									:table-props="{ bordered: true, striped: true }"
								></b-skeleton-table>
							</div>

							<div v-show="!editLoading">
                                <b-row>
                                    <b-col lg="6" md="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.name')+' *'"
                                            label-for="name"
                                            :invalid-feedback="formErrors.first('name')"
                                        >
                                            <b-form-input
                                                id="name"
                                                v-model="formFields.name"
                                                type="text"
                                                :state="((formErrors.has('name') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col lg="6" md="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.phone')+' *'"
                                            label-for="phone"
                                            :invalid-feedback="formErrors.first('phone')"
                                        >
                                            <b-form-input
                                                id="phone"
                                                v-model="formFields.phone"
                                                pattern="^[0-9-+()]*"
                                                title="+(XXX) XXX"
                                                type="number"
                                                :state="((formErrors.has('phone') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col lg="6" md="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.email')+' *'"
                                            label-for="email"
                                            :invalid-feedback="formErrors.first('email')"
                                        >
                                            <b-form-input
                                                id="email"
                                                v-model="formFields.email"
                                                type="text"
                                                :state="((formErrors.has('email') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col lg="6" md="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.password')+  ((!formFields.id || !formFields.is_u_exist) ? ' *' : '')"
                                            label-for="password"
                                            :invalid-feedback="formErrors.first('password')"
                                        >
                                            <b-form-input
                                                id="password"
                                                v-model="formFields.password"
                                                type="password"
                                                :state="((formErrors.has('password') ? false : null))"
                                                ref="password"
                                                @focus="$event.target.select()"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col lg="6" md="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.fax')"
                                            label-for="fax"
                                            :invalid-feedback="formErrors.first('fax')"
                                        >
                                            <b-form-input
                                                id="fax"
                                                v-model="formFields.fax"
                                                type="text"
                                                :state="((formErrors.has('fax') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col lg="6" md="6" sm="12">
                                        <b-form-group
                                            :label="$t('input.identificationNumber')+' *'"
                                            label-for="identification_number"
                                            :invalid-feedback="formErrors.first('identification_number')"
                                        >
                                            <b-form-input
                                                id="identification_number"
                                                v-model="formFields.identification_number"
                                                type="text"
                                                :state="((formErrors.has('identification_number') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row><!--/b-row-->
							</div>

							<div class="quick-modal-footer pt-1">
								<b-button
									size="sm"
									type="submit"
									variant="primary"
									:disabled="global.pendingRequests > 0"
									v-b-tooltip.hover :title="$t('button.title.save')"
								>
									<clip-loader style="display: inline" :loading="true" color="#fff"
									             size="12px"
									             v-if="global.pendingRequests > 0"></clip-loader>
									<i class="fa fa-save mr-1"></i>
									{{$t('button.save')}}
								</b-button>
								<b-button variant="warning" class="ml-3"
								          size="sm" @click="handleOperationClose"
								          v-b-tooltip.hover :title="$t('button.title.cancel')">
									<i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
								</b-button>
							</div><!--/.quick-modal-footer-->
						</b-col><!--/b-col-->
					</b-row><!--/b-col-->
				</form>
			</div><!-- /.brand-operation -->
		</b-modal>
	</fragment>
</template>
<script>
    import { Fragment } from 'vue-fragment'
    import Error from "./../../util/Error";
    import {request} from "../../util/Request";
    import {mapState} from "vuex";

    const FORM_STATE = {
        name: null,
        phone: null,
        email: null,
        password: null,
        fax: null,
        identification_number: null,
        comments: null,
        _method: 'post',
    };

    export default {
        props: ['allowCreate', 'allowUpdate', 'id', 'afterCreate', 'afterUpdate', 'afterCancel'],
        components: {
            Fragment
        },
	    data() {
	        return {
                formErrors: new Error({}),
                formFields: {...FORM_STATE},
		        visibility: false,
                editLoading: false,
                operationTitle: this.$t('title.addSupplier')
	        }
	    },
		methods: {
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: 'suppliers/quick/create/or/update',
                        method: 'post',
                        data: {
                            ...this.formFields,
                            id: this.id
                        },
                    })

                    if (this.formFields.id) {
	                    this.handleAfterSupplierUpdate(this.formFields, this.formFields.id);
                    } else {
                        this.handleAfterSupplierCreate(response.data);
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

					this.handleServerError(error)
                }
            },
            async handleEditClick(id) {
                try {
                    this.editLoading = true
                    const response = await request({
                        method: 'get',
                        url: `/suppliers/detail/${id}`,
                    })
                    this.operationTitle = this.$t('title.editSupplier')
                    const {data} = response
                    this.formFields = {
                        id: data.id,
                        name: data.name,
                        phone: data.phone,
                        email: data.email,
                        password: null,
                        fax: data.fax,
                        identification_number: data.identification_number,
                    }
                } catch (e) {
					this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                } finally {
                    this.editLoading = false
                }
            },
            handleAddSupplierClick() {
                this.operationTitle = this.$t('title.addSupplier');
                this.visibility = true
            },
            handleEditSupplierClick() {
                this.operationTitle = this.$t('title.editSupplier');
                this.visibility = true
	            this.handleEditClick(this.id)
            },
            handleOperationClose() {
                this.formFields = {...FORM_STATE}
                this.visibility = false
	            this.handleAfterOperationCancel()
            },
            handleAfterOperationCancel() {
                if (this.afterCancel) {
                    this.afterCancel()
                }
            },
            handleAfterSupplierCreate(inputs) {
                if (this.afterCreate) {
                    this.afterCreate(inputs)
                }
            },
            handleAfterSupplierUpdate(inputs) {
                if (this.afterUpdate) {
                    this.afterUpdate(inputs)
                }
            },
		},
	    computed: {
            ...mapState([
                'global',
                'settings'
            ]),
            hasId() {
                return (this.id)
            },
	    }
	}
</script>
